.blueComponenet {
  display: flex;
  justify-content: center;
  .blueComponenet-inside {
    display: flex;
    justify-content: flex-start;
    width: 150rem;
    margin: 20rem auto;
    padding: 0rem 0;

    .blueComponenet-text {
      width: 100rem;
      display: flex;
      // justify-content: center;
      flex-direction: column;
      margin: auto 0;
      margin-right: 17rem;

      h1 {
        margin-bottom: 6rem;
        font-size: 6.5rem;
      }

      p {
        color: #ffffff;
        font-weight: 300;
        font-size: 3.6rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .blueComponenet {
    min-height: auto;

    .blueComponenet-inside {
      padding: 5rem 0;
      width: auto;
      margin: 0 3rem;

      .blueComponenet-text {
        width: 100%;
        margin-right: 0;

        h1 {
          width: auto;
          font-weight: bold;
          line-height: auto;
          margin-bottom: 3rem;
        }

        p {
          width: auto;
          height: auto;
          font-size: 1.6rem;
        }

        .points {
          margin-top: 4.5rem;
        }

        a {
          text-align: center;
        }
      }
    }
  }
}

.screen-animate-blueComponent {
  animation: screen_animate_blueComponent 1s;
}

@keyframes screen_animate_blueComponent {
  0% {
    opacity: 0;
    transform: translateX(150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
