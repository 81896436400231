


.logo-anime {
  overflow-x: hidden;
  display: flex;
  position: absolute;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 10;
  animation: splash-anime 0.5s ease-in 3.5s forwards;
}



@keyframes splash-anime {
  0% {
    opacity: 1;
    overflow-y: hidden;
  }
  100% {
    visibility: hidden;
    opacity: 0;
    overflow-y: scroll;
  }
}

svg {
  background: transparent;
}

.I {
  left: 50%;
  transform: translatex(-50%);
  z-index: 3;
}

.C {
  left: 50%;
  opacity: 100%;
  z-index: 1;
  transform: translate(-80%);
  animation: c 2s ease-in-out forwards;
}

.S {
  z-index: 2;
  left: 50%;
  transform: translate(-20%);
  animation: s 2s ease-in-out forwards;
}

.C,
.S,
.I {
  position: absolute;
  background: transparent;
  overflow: visible;
}

.S > svg,
.C > svg {
  height: 28.3vh;
  width: 28.3vh;
}

.I > svg {
  height: 13vh;
  width: 12vh;
  opacity: 0%;
  animation: i-svg 1s ease-in-out 2s forwards;
}

.C > svg > path {
  animation: c-color 1s ease-in-out 2s forwards;
  fill: #1d2c5f;
}

.S > svg > path {
  fill: null;
  animation: s-color 1s ease-in-out 2s forwards;
}

.I > svg > mask > path {
  fill: null;
}

.I > svg > mask > rect {
  fill: null;
  animation: i-color 1s ease-in-out 2s forwards;
}

@keyframes i-color {
  from {
    fill: #30529a;
  }
  to {
    fill: white;
  }
}

@keyframes s-color {
  from {
    fill: #95c7ea;
  }
  to {
    fill: white;
  }
}

@keyframes c-color {
  from {
    fill: #1d2c5f;
  }
  to {
    fill: white;
  }
}

@keyframes i-svg {
  from {
    opacity: 0;
    /* height: 0vh;
    width: 0vh;  */

    height: 11.6vh;
    width: 11.6vh;
  }
  to {
    opacity: 1;
    height: 11.6vh;
    width: 11.6vh;
  }
}

@keyframes c {
  from {
    left: 100%;
    opacity: 0;
  }
  to {
    left: 50%;
    opacity: 1;
  }
}

@keyframes s {
  from {
    left: 0%;
    opacity: 0;
  }
  to {
    left: 50%;
    opacity: 1;
  }
}
