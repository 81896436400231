.investorProtection {
  .investorProtection-inside {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 23rem 0 23rem;
    padding: 8.5rem 0 12.6rem 0;
    .investorProtection-heading {
      margin-bottom: 2rem;
    }
    .investorProtection-sub_heading {
      margin-bottom: 3rem;
    }
    .investorProtection-disc {
      margin-bottom: 3rem;
    }
    .investorProtection-sub_headings {
      margin-bottom: 2rem;
    }
    .investorProtection-points {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .sub-heading {
      color: #f5f9fc;
      font-weight: 500;
      font-size: 26px;
    }

    .investorProtection-description {
      color: #f5f9fc;
      font-weight: 300;
      font-size: 26px;
    }

    .content-heading {
      color: #8b6cd8;
      font-weight: 800;
      font-size: 35px;
    }
  }
}

@media (max-width: 600px) {
  .investorProtection {
    .investorProtection-inside {
      margin: 0 2rem 0 2rem;
      padding: 4.6rem 0 5.2rem 0;
    }
  }
}
