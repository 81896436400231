.teamsContainer {
  .heading {
    padding-top: 7rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 11.2rem;

    text-align: center;
    margin-bottom: 2rem;
  }

  .desc {
    margin: auto;
    margin-bottom: 9.2rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 2.6rem;
    text-align: center;
    width: 150rem;
    text-align: center;
  }
  .cardContainer {
    margin: 0 10rem 0 10rem;
  }

  .card {

    border: 0.1rem solid #D9D9D9;
    border-radius: 1.0rem;
    // width: 75rem;
    // height: 40rem;

    img {
      text-align: center;
      width: 26.2rem;
      height: 28.6rem;
    }

    display: flex;
    justify-content: center;
    padding: 5rem;

    .textCard {
      margin-left: 5rem;

      p {
        font-weight: 500;
        font-size: 2.4rem;

      }

      .designation {
        font-size: 3.6rem;
        margin-bottom: 1.9rem;
      }

      .name {
        font-size: 3.2rem;
        text-align: right;
        margin-top: 2.4rem;
      }
    }
  }

  .cardContainer {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 5rem;
    row-gap: 5rem;
    margin-bottom: 10rem;

  }


}


@media (max-width: 600px) {
  .teamsContainer {
    .heading {
      padding-top: 7rem;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 7.5rem;
      line-height: 11.2rem;

      text-align: center;
    }

    .desc {
      margin-bottom: 6rem;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 2.6rem;
      text-align: center;
    }

    .card {
      img {
        max-width: 25rem;
        min-width: 10rem;
      }

      padding: 1.0rem;
      text-align: justify;
      padding: 10rem;

      .textCard {
        margin-left: 2rem;

        p {
          font-weight: 500;
          font-size: large;
          padding-right: 10rem;

        }

        .designation {
          font-size: large;
        }

        .name {
          font-size: x-large;
          text-align: right;
          padding-right: 10rem;
        }
      }
    }

    .cardContainer {
      display: block;
      padding: 1.0rem;
    }


  }

}