.creators {
    height:100vh;
    .creators-landing-container {

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-10rem);

    .creators-inside {
      display: flex;
      justify-content: space-between;
      margin: auto 13rem;
      margin-left: 20rem;

        .creators-content {
        margin-top: 15rem;
        margin-left: 10rem;
          width: 76rem;

            h1 {
                width: 82.6rem;
                font-size: 4rem;
            }
    
            p {
                margin-top: 2.2rem;
                margin-bottom:3rem;
                font-weight: 600;
            }
            button {
                margin-top: 6rem;
                background-color: #3696D2;
                width: 20.1rem;
                height: 4.7rem;
                border-radius: 2.9rem;
                color: #fff;
                font-size: 2.2rem;
                border-width: 0;      
                      
            }
    
            div {
                margin-top: 2rem;
                display: flex;
                justify-content: space-between;

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    img {
                        width: 13rem;
                        height: 13rem;
                    }
                    p {
                        text-align: center;
                    }
                }
            }

            .creators-icon-text {
                margin-top: 3rem;
                font-size: 1.8rem;
                color: #C792EB;
                background-color: #FFFFFF;
                border-radius:2rem;
                padding: 2rem;
                min-height: 13.5rem;
            }
        }
    }
}
}

@media (max-width: 600px) {
    .creators {
        height: auto;
        .creators-landing-container {

    height: auto;
    display: block;
    transform: translateY(0);
        .creators-inside {
            margin: 0 2rem;
            padding: 2.9rem 0 3rem 0;
    
            .creators-content {

        margin-left: 0;
                width: 72rem;
                margin-top:0rem;
    
                h1 {
                    width: auto;
                    font-size: 2.3rem;
                    margin-bottom: 3.7rem;
                }
        
                p {
                    margin: auto;
                    margin-top: 3rem;
                    font-weight: 500;
                    margin-bottom: 2.4rem;
                }

                button {
                    display: block;
                    margin: 3rem auto 5rem auto;
                    width: 14rem;
                    height:3rem;
                    font-size:1.3rem;
                }
        
                .creators-icons {
    
                    div {
    
                        img {
                            width: 7.7rem;
                            height: 7.7rem;
                        }
                        p {
                            text-align: center;
                        }
                    }
                }
    
                .creators-icon-text {
                    margin-top: auto;
                    font-size: 1.6rem;
                    width: auto;
                    border-radius:2rem;
                    padding: 3rem;
                }
            }
        }
    }

    .creators-image-phone {
        margin: auto;
    }
}
    
}


.screen-animate-creators {
    animation: screen_animate_creators 1s;
    }
    
    @keyframes screen_animate_creators {
        0% {
            opacity: 0;
            transform: translateX(150%);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }