.contactus-up {
  padding-bottom: 2rem;
  margin: auto;
  margin-top: 22rem;
  width: 150rem;
  background-color: white;
  border-radius: 2.3rem;
  height: auto;
}

.contactus-upper {
  padding-bottom: 15rem;
}

img.contactus-bg {
  transform: translate(-50%, -50%);
  width: 25rem;
}

.contactus-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .contactus {
    // margin: 17rem 0 0 17rem;
    margin: 5rem 0 0 5rem;

    h3 {
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 700;
      font-size: 3.44882rem;
      line-height: 4.7rem;
      color: #0a142f;
      margin-bottom: 3rem;
    }

    p {
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 400;
      font-size: 1.88561rem;
      line-height: 2.8rem;
      color: #0a142f;

      transform: translateX(2rem);
    }
  }
}

.contactus-inside {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: white;
  border-radius: 1.5rem;
}

.contactus-content > h2 {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 4.5rem;
  line-height: 5rem;
  margin-bottom: 3rem;
}

.contactus-points {
  margin-left: 4rem;
  font-size: 2rem;
  line-height: 3rem;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
}

.contactus-input {
  box-shadow: 22px 63.1761px 82.8511px rgba(24, 23, 23, 0.1);
  border-radius: 1.5rem;
  width: 50rem;
  background-color: white;
  position: relative;
  align-items: center;
  transform: translate(-10rem, -15rem);
}

.contactus-input-in {
  padding: 9rem 5rem;
}

.text {
  height: 4rem;
  width: 90%;
  border-radius: 0.5rem;
  font-size: 2rem;
  line-height: 2.3rem;
  height: 5rem;
  padding-left: 2rem;
  border: 1.17573px solid #d9d9d9;
  border-radius: 3.91911px;
  border-width: 0.15rem;
  text-align: left;
  margin-bottom: 2.5rem;
  color: #6d6d6d;
}

.message {
  width: 90%;
  height: 25rem;
  background: #ffffff;
  border: 1.17573px solid #d9d9d9;
  border-radius: 3.91911px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;

  padding-left: 2rem;
  padding-top: 1.6rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.3rem;
  resize: none;
}

.contactus-submit {
  width: 17.2rem;
  height: 5.3rem;

  background: #1c2b5e;
  border-radius: 1rem;

  margin-top: 3.2rem;

  font-weight: 500;
  font-size: 2rem;
  line-height: 2.3rem;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}

.error {
  border-color: tomato;
  border-width: 0.3rem;
  color: red;
}

.error::placeholder {
  color: red;
}

.contactus-spinner {
  height: 3rem;
  width: 3rem;
}
@media (max-width: 960px) {
  .contactus-bg {
    display: none;
  }

  .contactus-up {
    margin-left: 0;
    padding-bottom: 2rem;
    margin: auto;
    margin-top: 20rem;
    width: 100rem;
    background-color: #d9d9d9;
    border-radius: 2.3rem;
    height: auto;
  }

  .contactus {
    box-shadow: none;
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    width: auto;
  }

  .contactus-inside {
    flex-direction: column;
  }

  .contactus-content {
    margin-top: 2.8rem;
    height: auto;
    box-shadow: 0px 3.13529px 20px rgba(51, 51, 51, 0.1);
    border-radius: 1.5rem;
    margin-bottom: 2.8rem;
    width: 100%;
    padding-right: 0;
    justify-content: center;
  }

  .contactus-content > h2 {
    font-size: 3rem;
    padding-left: 2.8rem;
    margin-bottom: 0;
  }

  .contactus-points {
    width: auto;
    padding-left: 4rem;
    margin-left: 0rem;
  }

  .contactus-point {
    font-size: 2.4rem;
    font-weight: normal;
    line-height: 2.8rem;
  }

  .contactus-input {
    box-shadow: 0px 3.14px 20px rgba(51, 51, 51, 0.1);
    border-radius: 1.5rem;
    width: auto;
    transform: none;
    margin-bottom: 5rem;
    margin-top: -14rem;
  }

  .contactus-input-in {
    padding: 2.2rem;
  }

  .text {
    height: 4.38rem;
    width: 90%;
    border-radius: 0;
    font-size: 1.4rem;
    padding-left: 1.4rem;
    margin-bottom: 1.5rem;
    border: 0.05rem solid #e5e5e5;
    border-radius: 0.89rem;
  }

  .name {
    border: 0.05rem solid #0a142f;
  }

  .message {
    width: 92.7%;
    height: 14.55rem;
    border: 0.562886px solid #e5e5e5;
    border-radius: 0.89rem;
  }

  .contactus-submit {
    width: 17.7rem;
    height: 4.4rem;
    border-radius: 0.89rem;
    font-size: 1.4rem;
    font-weight: 600;
  }
}
@media (max-width: 600px) {
  .contactus-bg {
    display: none;
  }

  .contactus-up {
    margin-left: 0;
    padding-bottom: 2rem;
    margin: auto;
    margin-top: 22rem;
    // width: 150rem;
    background-color: white;
    border-radius: 2.3rem;
    // height: 80rem;
    width: 40rem;
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .contactus {
    box-shadow: none;
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    width: auto;
    display: none;
  }

  .contactus-inside {
    flex-direction: column;
  }

  .contactus-content {
    margin-top: 2.8rem;
    height: auto;
    box-shadow: 0px 3.13529px 20px rgba(51, 51, 51, 0.1);
    border-radius: 1.5rem;
    margin-bottom: 2.8rem;
    width: 100%;
    padding-right: 0;
    display: flex;
    justify-content: center;
  }

  .contactus-content > h2 {
    font-size: 2rem;
    padding-left: 2.8rem;
    margin-bottom: 0;
  }

  .contactus-points {
    width: auto;
    padding-left: 4rem;
    margin-left: 0rem;
  }

  .contactus-point {
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 2.8rem;
  }

  .contactus-input {
    box-shadow: 0px 3.14px 20px rgba(51, 51, 51, 0.1);
    border-radius: 1.5rem;
    width: auto;
    transform: none;
    margin-bottom: 5rem;
  }

  .contactus-input-in {
    padding: 2.2rem;
  }

  .text {
    height: 4.38rem;
    width: 94%;
    border-radius: 0;
    font-size: 1.4rem;
    padding-left: 1.4rem;
    margin-bottom: 1.5rem;
    border: 0.05rem solid #e5e5e5;
    border-radius: 0.89rem;
  }

  .name {
    border: 0.05rem solid #0a142f;
  }

  .message {
    width: 92.7%;
    height: 14.55rem;
    border: 0.562886px solid #e5e5e5;
    border-radius: 0.89rem;
  }

  .contactus-submit {
    width: 17.7rem;
    height: 4.4rem;
    border-radius: 0.89rem;
    font-size: 1.4rem;
    font-weight: 600;
  }
}
