.chatMode {
  position: relative;
  overflow: hidden;
  .chatMode-inside {
    margin: 0 10rem 0 10rem;
    padding: 7.7rem 0 15rem 0;

    .chatMode-content {
      width: auto;
      h1 {
        width: auto;
        font-weight: 700;
        font-size: 4rem;
        text-align: center;
      }

      .chat-modes {
        margin-top: 8.5rem;
        display: flex;
        justify-content: space-between;
        div {
          margin-bottom: 3.8rem;
          text-align: center;
          div {
            margin: auto;
            width: 16rem;
            height: 16rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;

            .icon {
              width: 17.2rem;
              height: 16.613rem;
            }
          }

          h5 {
            margin-top: 3rem;
            font-weight: 700;
          }

          p {
            margin-top: 4.1rem;
            width: 45rem;
            font-weight: 300;
            text-align: center;
          }
        }
        .normal-mode {
          div {
            background-color: #1f89ca;
            align-items: center;
          }
        }
        .privacy-mode-private {
          div {
            background-color: #1f89ca;
          }
        }

        .privacy-mode-schedule {
          div {
            background-color: #d878ff;
          }
        }
      }
    }

    .chatMode-tech {
      position: relative;
      .chat-mode-tech-inside {
        min-height: 40rem;
        position: relative;
        border: 5px solid #ffffff;
        border-radius: 4rem;
        margin-top: 10rem;
        margin-bottom: 10rem;

        div {
          margin: 5rem;

          .common-h1 {
            margin-bottom: 3rem;
            font-size: 4rem;
            font-weight: 600;
          }
        }
      }

      .chatMode-tech-right {
        position: absolute;
        top: -10rem;
        transform: translateX(97%) scale(0.9, 0.9);
      }
    }
  }
}

.chatMode-tech-right-animate {
  animation: chatmode-tech-right 1s;
}

.chatMode-tech-center-animate {
  animation: chatmode-tech-center 1s;
}

@keyframes chatmode-tech-right {
  0% {
    transform: translateX(97%) scale(0.9, 0.9);
  }

  100% {
    transform: translateX(0%) scale(1, 1);
  }
}

@keyframes chatmode-tech-center {
  50% {
    opacity: 0;
    transform: translateX(-97%) scale(0.9, 0.9);
  }

  0% {
    opacity: 1;
    transform: translateX(0%) scale(1, 1);
  }

  51% {
    opacity: 0;
    transform: translateX(197%) scale(0.9, 0.9);
  }
  100% {
    opacity: 1;
    transform: translateX(97%) scale(0.9, 0.9);
  }
}

@media (max-width: 600px) {
  .chatMode {
    .chatMode-inside {
      margin: 0 2rem;
      padding: 4.2rem 0;

      .chatMode-content {
        h1 {
          font-weight: 700;
          font-size: 3.7rem;
        }

        .chat-modes {
          margin-top: 2.3rem;
          div {
            margin-bottom: 1.8rem;
            div {
              width: 4.6rem;
              height: 4.6rem;

              .icon {
                width: 4.6rem;
                height: 4.6rem;
              }
            }

            h5 {
              margin-top: 0.7rem;
              font-size: 1.2rem;
            }

            p {
              margin-top: 0.6rem;
              width: 12.9rem;
              font-size: 1.2rem;
            }
          }
        }
      }

      .chatMode-tech {
        border-radius: 3.9rem;
        margin-top: 1.8rem;
        margin-bottom: 3rem;

        div {
          margin: 2.5rem;

          .common-h1 {
            margin-bottom: 3rem;
            font-size: 2rem;
            font-weight: 600;
          }

          .common-p {
            font-size: 1.6rem;
          }
        }

        .chat-mode-tech-inside {
          width: 80%;
          min-height: 80rem;
        }

        .chatMode-tech-right {
          transform: translateX(110%) scale(0.9, 0.9);
        }
        .chatMode-tech-right-animate {
          animation: chatmode-tech-right-mobile 1s;
        }

        .chatMode-tech-center-animate {
          animation: chatmode-tech-center-mobile 1s;
        }
      }
    }
  }
}

@keyframes chatmode-tech-right-mobile {
  0% {
    transform: translateX(110%) scale(0.9, 0.9);
  }

  100% {
    transform: translateX(0%) scale(1, 1);
  }
}

@keyframes chatmode-tech-center-mobile {
  50% {
    opacity: 0;
    transform: translateX(-110%) scale(0.9, 0.9);
  }

  0% {
    opacity: 1;
    transform: translateX(0%) scale(1, 1);
  }

  51% {
    opacity: 0;
    transform: translateX(197%) scale(0.9, 0.9);
  }
  100% {
    opacity: 1;
    transform: translateX(110%) scale(0.9, 0.9);
  }
}
