.investorEcosystem {
  .investorEcosystem-inside {
    align-items: center;
    text-align: center;
    h3 {
      padding-top: 5rem;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 4rem;
      line-height: 10rem;
      color: #000;
    }

    p {
      margin: auto;
      margin-top: 1rem;
      margin-bottom: 14rem;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 2.6rem;
      // width: 150rem;
      /* or 48px */

      // text-transform: capitalize;

      color: #000;
    }
    img {
      // padding-bottom: 14rem;
      align-items: center;
      margin: auto;
      height: 132rem;
      width: 92rem;
    }
  }
}

@media (max-width: 875px) {
  .investorEcosystem {
    .investorEcosystem-inside {
      justify-content: space-between;
      margin: 0 2rem 0 2rem;
      padding: 4.5rem 0 5.2rem 0;

      button {
        margin: 0 auto;
        margin-top: 5.5rem;
        margin-bottom: 5.5rem;
        width: 16.4rem;
        height: 3.85rem;
        border-radius: 6.9rem;
        font-size: 1.8rem;
      }
      img {
        // padding-bottom: 14rem;
        align-items: center;
        margin: auto;
        height: 66rem;
        width: 46rem;
      }
      .heading-container {
        margin-bottom: 2rem;
      }
      .investorEcosystem-heading2 {
        margin-top: 2rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}
