h1.blog_heading {
    margin-top: 5rem;
    color: #1A3677;
    font-family: "Sen",
        sans-serif;
    font-size: 4rem;
    font-weight: 300;
    width: 100%;
    text-align: center;
    margin-bottom: 5rem;
}

a.each_blog {
    padding: 5rem;
    display: flex;
    max-width: 85rem;
    height: min-content;
    border-radius: 0.6rem;
    margin-bottom: 5rem;
    text-decoration: none;
    transition: all 0.3s ease;
    align-items: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    transition: all .3s ease;
}



.blog_img>img {
    width: 24rem;
    object-fit: cover;
    margin-right: 5rem;
    border-radius: 0.7rem;
    box-shadow: 0 1.5rem 2.6rem rgb(0 0 0 / 40%);
}

.blog_head {
    font-size: 2rem;
    font-weight: 600;
    font-family: "Sen", sans-serif;
    color: #25aae1;
}

.blog_date {
    font-size: 1.2rem;
    margin-left: 2rem;
    color: #333;
    font-family: "Sen",sans-serif;
}

.blog_date:before {
    content: '/';
    color: #1A3677;
    font-size: 2.5rem;
    top: auto;
    position: relative;
    margin-right: 1rem;
}

.blog_divider {
    background-image: linear-gradient(to right, #1A3677, #16A0D0);
    width: 7rem;
    height: 0.5rem;
    border-radius: .25rem !important;
    box-sizing: border-box;
    margin: 1rem 0rem;
}

.blog_content {
    font-family: "Sen", sans-serif;
    font-size: 1.4rem;
    box-sizing: border-box;
    color: #000;
}

.blog_arrow {
    font-size: 2rem;
    box-sizing: border-box;
    height: 2rem;
    width: 15%;
    position: relative;
    text-align: center;
    font-size: 3.5rem;
    left: 0;
    padding-left: 2rem;
    transition: all .3s ease;
}

.blog_arrow>svg {
    width: 5rem;
    height: 2rem;
}

a.each_blog:hover {
    box-shadow: 0 1rem 5rem 0 rgba(0, 0, 0, 0.2);
    transition: all .3s ease;
}

a.each_blog:hover>.blog_arrow {
    left: 3rem;
    transition: all .3s ease;
}


a.each_blog:hover>.blog_arrow>svg>path {
    fill: #25aae1;
}

@media (max-width:600px) {
    h1.blog_heading {
        font-size: 2.5rem;
    }

    a.each_blog {
        flex-direction: column;
        max-width: auto;
        padding: 3rem;
    }

    .blog_img>img {
        width: 100%;
        height: 15rem;
        margin-bottom: 2.5rem;
    }
}