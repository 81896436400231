.accounts {
  // margin-top: 6.8rem;
  // margin-bottom: 20rem;
  justify-content: center;

  .account-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    text-transform: capitalize;
    color: #000;
    text-align: center;
  }

  .account-subHeading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 2.6rem;
    text-align: center;
    color: #000;
    margin: 2rem auto 6rem auto;
  }

  .accounts-inside {
    display: flex;
    justify-content: space-between;
    margin: 0 23rem;

    .accounts-content {
      width: 85rem;
      height: 65rem;
      margin-left: -5rem;

      .accounts-choose {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 2.7rem;
        color: #1C2B5E;
      }

      .accounts-icons {
        margin-top: 4.5rem;
        display: flex;
        justify-content: space-around;

        div {
          display: flex;
          flex-direction: column;

          p {
            margin: 3rem auto 0 auto;
            margin-top: 3rem;
          }

          div {
            width: 15rem;
            height: 15rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .accounts-all {
          background: #f2f2f2;
        }

        .accounts-public {
          img {
            width: 10rem;
            height: 10em;
          }
        }

        .accounts-family {
          img {
            width: 7.8rem;
            height: 8.1rem;
          }
        }

        .accounts-friends {
          img {
            width: 9rem;
            height: 9rem;
          }
        }

        .accounts-work {
          img {
            width: 9rem;
            height: 9rem;
          }
        }

        .accounts-pay {
          img {
            width: 9rem;
            height: 9rem;
          }
        }
      }

      p {
        margin-top: 3.5rem;
        font-weight: 500;
        margin: 2rem 0 2rem 0.4rem;
        font-size: 2rem;
        // color: #fff;
      }

      .accounts-discription {
        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 3.6rem;
          text-transform: capitalize;
          color: #000;
          margin-top: 2rem;
        }

        font-weight: 600;
        height: auto;
        background: #f2f2f2;
        font-size: 2.4rem;
        width: 74.6rem;
        padding: 4rem;
        margin: 0 auto;
        margin-top: 12rem;
        border-radius: 1.7rem;
        color: #adadad;
      }
    }
  }
}

@media (max-width: 875px) {
  .screen {
    width: 14rem;
    height: 26.8rem;
  }
}

@media (max-width: 875px) {
  .accounts {
    height: auto;
    margin-top: -1rem;

    .accounts-inside {
      margin: 3rem;
      // padding-bottom: 2rem;
      margin-bottom: -1rem;

      .accounts-content {
        width: auto;
        margin-top: 0;
        margin-left: auto;

        h1 {
          margin-bottom: 3rem;
        }

        .accounts-icons {
          margin-top: 1.5rem;

          div {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
              margin: 1.5rem auto 3rem auto;
            }

            div {
              width: 7.3rem;
              height: 7.3rem;
              border-radius: 50%;
            }
          }

          .accounts-public {
            img {
              width: 3rem;
              height: 3.2rem;
            }
          }

          .accounts-family {
            img {
              width: 3rem;
              height: 3rem;
            }
          }

          .accounts-friends {
            img {
              width: 3rem;
              height: 3.1rem;
            }
          }

          .accounts-work {
            img {
              width: 4.3rem;
              height: 3.2rem;
            }
          }

          .accounts-pay {
            img {
              width: 4.3rem;
              height: 4rem;
              margin-left: 5px;
              margin-top: 5px;
            }
          }
        }

        p {
          margin-top: 2.5rem;
          font-weight: 500;
          text-align: center;
        }

        .accounts-discription {
          font-size: 2rem;
          width: auto;
          padding: 1.5rem;
          margin: 0 auto 3rem auto;
        }
      }
    }
  }
}

.screen-animate-accounts {
  animation: screen_animate_accounts 1s;
}

@keyframes screen_animate_accounts {
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}