.landingPage {
  width: 100%;
  padding-bottom: 10rem;
  align-items: center;
  position: relative;

  .landingPage-container {
    padding: 0 0 0 0;

    .landingPage-heading {
      // width: 100%;
      margin: 0 auto;
      margin-top: 5rem;
      margin-bottom: 5rem;
      font-weight: 900;
      font-size: 3.2rem;
      text-align: center;
      color: #1C2B5E;
    }

    .landingPages-wrapper {
      display: flex;
      justify-content: space-around;

      .landingPages-image-wrapper {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        .landingPage-images {
          height: 70rem;
          margin: 2rem 0 0 0;
          position: relative;

          .screen2-image {
            margin: auto;
            position: absolute;
            left: 50%;
            top: 40%;
            transform: translate(-50%, -50%);
            z-index: -1;
          }

          .screen2-image-left {
            left: 3%;
          }

          .screen2-image-right {
            left: 97%;
          }

          .screen2-image {
            width: 25rem;
            height: 50rem;
          }

          .screen1-image {
            width: 27rem;
            height: 55rem;

            z-index: 10;
          }
        }

        .landingPage-button {
          margin: -10rem auto 0;
          width: 22rem;
          height: 7rem;
          border-radius: 7rem;
          border-width: 0;
          font-size: 2.4rem;
          font-weight: 500;
          color: #fff;
          // padding: 2rem;
          display: block;
          font-family: Poppins;
          text-decoration: none;
          text-transform: none;
          transition: all 0.2s ease-out;
        }

       .super{
          background-color: #1C2B5E;
          border: #1C2B5E;
        }

        .super:hover{
          background-color: #fff;
          padding-top: -6rem;
          border-style: solid;
          border-color: #1C2B5E;
          border-width: thick;
          color:#1C2B5E;
        }

        .Crypto{
           background-color: #3696d2;
        }

        .Crypto:hover{
          background-color: #fff;
          border-style: solid;
          border-color: #3696d2;
          border-width: thick;
          color:#3696d2;
        }

        .cornar{
          background-color: #FFA621;
       }

       .cornar:hover{
         background-color: #fff;
         border-style: solid;
         border-color: #FFA621;
         border-width: thick;
         color:#FFA621;
       }

        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 900px) {
  .landingPage {
    height: auto;
    padding-bottom: 5rem;

    .landingPage-container {
      margin: 0 2rem;
      padding: 0 0 0 0;

      .landingPage-heading {
        width: auto;
        margin: 0 auto;
        height: auto;
        font-size: 2rem;
      }

      .landingPage-images {
        height: 30rem;

        .screen1-image {
          width: 10.6rem;
          height: 21.6rem;
        }

        .screen2-image {
          width: 9.6rem;
          height: 19.2rem;
        }

        .screen3-image {
          width: 7.2rem;
          height: 17.5rem;
        }
      }

      .landingPage-text {
        font-size: 1.6rem;
      }

      button {
        margin-top: 2.7rem;
        width: 21rem;
        height: 3.7rem;
        font-size: 1.6rem;
        margin-bottom: 3rem;
      }
    }
  }

  .popup {
    .popup-inside {
      width: 55rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -47%);

      .popup-heading {
        height: 11rem;

        h4 {
          font-size: 2.4rem;
        }
      }

      .popup-content {
        padding: 2rem 8rem;

        div {
          .popup-form {
            h5 {
              font-size: 1.2rem;
            }

            input {
              border: 0.5px solid #dfdfdf;
              border-radius: 5px;
              height: 4rem;
              font-size: 1.2rem;
              margin-top: 0.7rem;
              padding-left: 1.7rem;
            }

            .popup-phone {
              margin-top: 1rem;
            }

            button {
              margin: 0 auto;
              margin-top: 2rem;
              width: 21rem;
              height: 3.7rem;
              border-radius: 3.6rem;
              font-size: 1.5rem;
            }
          }
        }
      }

      .closePng {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}

.error {
  border-color: tomato !important;
  border-width: 0.3rem;
  color: red !important;
}

.screen1-image {
  animation: screen1_image_anime 1s;
}

@keyframes screen1_image_anime {
  0% {
    transform: translate(10%, 50%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}

.screen2-image-right {
  animation: screen2-image-right 1s;
}

@keyframes screen2-image-right {
  0% {
    transform: translate(-100%, -10%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

.screen2-image-left {
  animation: screen2-image-left 1s;
}

@keyframes screen2-image-left {
  0% {
    transform: translate(0%, -10%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}


Button .super{
  background-color: #1C2B5E;
}