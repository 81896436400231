.rewards {
    display: flex;
    justify-content: center;


    .rewards-inside {
        display: flex;
        justify-content: flex-start;
        width:140.2rem;
        margin: 13rem auto;

        .rewards-text {
            display: flex;
            justify-content: start;
            flex-direction: column;
            margin-right: 17rem;

            h1 {
                font-size: 5rem;
                width: 74.7rem;
                margin-bottom: 2.5rem;
            }

            p {
                width: 71.1rem;
                height: 19.2rem;
                color: #FFFFFF;
                font-size: 2.6rem;
                font-weight: 300;
            }
        }
    }
}

@media (max-width: 600px) {
    .rewards {    
    
        .rewards-inside {
            width:auto;
            margin: 3rem;
    
            .rewards-text {
                margin-right: 0;
    
                h1 {
                    width: auto;
                    font-weight: bold;
                    line-height: auto;
                    margin-bottom: 3rem;
                }
    
                p {
                    margin-top: 3rem;
                    width: auto;
                    height: auto;
                }
            }
        }
    }
}