/* Base styles for the navbar */
.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // background-color: fff;

  .navbar-inside {
    width: 100%;
    height: 10rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    margin-left: 10rem;

    .navbar-logo {
      a {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 0rem;

        div {
          width: 6rem;
          height: 6rem;
          border-radius: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 2rem;

          img {
            top: 50%;
            width: 10rem;
            height: 5.7rem;
          }
        }

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 3.6rem;
          line-height: 5.4rem;
        }
      }
    }

    .toggle-button{
      position: absolute;
      top: 3rem;
      right: 2rem;
      display: none;
      flex-direction: column;
      justify-content: space-between;
      width: 30px;
      height: 21px;

      .bar{
        width: 100%;
        height: 3px;
        background-color: #000000;
      }
    }

    .navbar-links {
      width: 65rem;
      position: relative;
      display: flex;
      gap: 10px;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 5rem;
      transition: all 0.5s ease-in-out;



      a {
        text-decoration: none;
        font-size: 2.5rem;
        color: #ffffff;
        font-family: "Poppins";
        padding: 5px 2px;
        transition: all 0.2s ease-out;
        // border-width: thick;
        border-radius: 50px;
        font-style: normal;
        font-weight: 500;
        margin-left: 4rem;
      }

      .super{
        position: relative;
        letter-spacing: 0.5px;
        color: #1C2B5E;
        font-weight: 600;
      }

      .super::after{
        content: '';
        position: absolute;
        background-color: #1C2B5E;
        height: 4px;
        width: 0;
        bottom: -5px;
        left: 0;
        transition: all 0.4s ease-in-out;
      }

      .super:hover::after{
        width: 100%;
      }

      .crypto{
        position: relative;
        letter-spacing: 0.5px;
        color: #2E8FCB;
        font-weight: 600;
      }

      .crypto::after{
        content: '';
        position: absolute;
        background-color: #2E8FCB;
        height: 4px;
        width: 0;
        bottom: -5px;
        left: 0;
        transition: all 0.4s ease-in-out;
      }

      .crypto:hover::after{
        width: 100%;
      }

      .cornar{
        position: relative;
        letter-spacing: 0.5px;
        color: #FFA621;
        font-weight: 600;
      }

      .cornar::after{
        content: '';
        position: absolute;
        background-color: #FFA621;
        height: 4px;
        width: 0;
        bottom: -5px;
        left: 0;
        transition: all 0.4s ease-in-out;
      }

      .cornar:hover::after{
        width: 100%;
      }
      
     
    }
  }
}

/* Media query for smaller screens */
@media (max-width: 875px) {
  .navbar {
    margin-bottom: 10rem;
    padding: 5px;
    .navbar-inside {
      height: auto;
      margin-left: 0rem;
      flex-direction: column;
      align-items: flex-start;

      .navbar-logo {
        // position: fixed;
        // left: 0;
        // margin-left: -35rem;
        a{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-right: 0rem;
          right: 0;

          div {
            width: 5rem;
            height: 5rem;
            background-color: white;
            border-radius: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              top: 50%;
              width: 3.5rem;
              height: 3.7rem;
            }
          }

          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 2.3rem;
            line-height: 2.4rem;
            color: #ffffff;
          }
        }
      }

      .toggle-button{
        display: flex;
      }

      
      .navbar-links {
          // display: none;
          // position: fixed;
          background-color: rgb(238, 238, 238);
          left: 0;
          flex-direction: column;
          gap: 20px;
          width: 100%;
          height: fit-content;
          margin-top: 2rem;
          transition: all 0.5s ease-in-out;
          padding: 20px;

          a{
            width: fit-content;
            font-size: 2.3rem;
            text-align: center;
          }
        
      }
    }
  }

  
}
