.whiteComponent {
  width: 100%;
  margin: 20rem 0 20rem 0;
  display: flex;
  justify-content: center;

  .whiteComponent-inside {
    width: 150rem;
    margin: auto auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .whiteComponent-text {
      width: 100rem;
      height: auto;
      display: flex;
      margin: auto 0;
      flex-direction: column;

      h1 {
        font-weight: bold;
        margin-bottom: 6rem;
        font-size: 6.5rem;
      }

      p {
        font-weight: 300;
        font-size: 3.6rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .whiteComponent {
    min-height: auto;

    .whiteComponent-inside {
      width: auto;
      margin: 3rem;

      .whiteComponent-text {
        width: 100%;
        height: auto;

        h1 {
          margin-bottom: 3rem;
        }

        p {
          width: auto;
          height: auto;
          font-size: 1.6rem;
        }

        .points {
          margin-top: 4.5rem;
        }
      }
    }
  }
}

.screen-animate-whiteComponent {
  animation: screen_animate_whiteComponent 1s;
}

@keyframes screen_animate_whiteComponent {
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
