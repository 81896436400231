.creatorEcosystem{ 
    .creatorEcosystem-inside{ 
        justify-content: space-between;
        margin: 0 23rem 0 23rem;
        padding: 4.5rem 0 12.6rem 0;
      
        button {
            margin: 0 auto;
                margin-top: 5.5rem;
                margin-bottom: 5.5rem;
                background-color: #3696D2;
                width:31.1rem;
                height:6.3rem;
                border-radius:2.9rem;
                border-width: 0;
                font-size:3rem;
                font-weight: 500;
                color: #FFFFFF;
                display: block;
                
        }
        .heading-container{
            margin-bottom: 2rem;
        }
        .creatorEcosystem-heading2{ 
            margin-top: 2rem;
            margin-bottom: 1.5rem;
        }
    }  
}

@media (max-width: 600px) { 
    .creatorEcosystem{ 
    .creatorEcosystem-inside{ 
        justify-content: space-between;
        margin: 0 2rem 0 2rem;
        padding: 4.5rem 0 5.2rem 0;
      
        button {
            margin: 0 auto;
                margin-top: 5.5rem;
                margin-bottom: 5.5rem;
                width:16.4rem;
                height:3.85rem;
                border-radius:6.9rem;
                font-size:1.8rem;
        }
        .heading-container{
            margin-bottom: 2rem;
        }
        .creatorEcosystem-heading2{ 
            margin-top: 2rem;
            margin-bottom: 1.5rem;
        }
    }  
}
}