.vision {
    .vision-inside {
        display: flex;
        justify-content: space-between;
        margin: 0 28rem 0 26rem;
        padding: 19rem 0 22rem 0;
        
        .vision-content {
            width: 88rem;

            p {
                margin-top: 2rem;
                margin-bottom: 8.5rem;
            }

            div {
                button {
                    border-radius: 6.9rem;
                    height: 11.3rem;
                    width: 41rem;
                    font-weight: 500;
                    font-size: 3rem;
                    color: #ffffff;
                    border-color: #3696D2;
                }

                .rules {
                    background-color: #3696D2;
                    color: #ffffff;
                    border-width: 0;
                    margin-right: 5rem;
                }

                .learn {
                    background-color: transparent;
                    border: 2px solid #4994EC;
                }
            }
        }

        img {
            margin-top: 5rem;
        }
    }
}

@media (max-width: 600px) {
    .vision {
        .vision-inside {
            margin: 0 2rem;
            padding: 4rem 0 10rem 0;
            
            .vision-content {
                width: 88rem;
    
                p {
                    margin-top: 2rem;
                    margin-bottom: 3rem;
                }
    
                div {
                    display: flex;
                    justify-content: space-between;
                    button {
                        height: 4.3rem;
                        width: 15.6rem;
                        font-size: 1.6rem;
                    }
    
                    .rules {
                        margin-right: 5rem;
                    }
    
                    .learn {
                        border: 1.5px solid #4994EC;
                    }
                }
            }
    
            img {
                margin-top: 5rem;
                margin-bottom: 5rem;
            }
        }
    }
}