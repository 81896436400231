.dataProtection {
  .dataProtection-inside {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 23rem 0 23rem;
    padding: 8.5rem 0 12.6rem 0;
    .createrprotection-heading {
      margin-bottom: 2rem;
    }
    .createrprotection-sub_heading {
      margin-bottom: 3rem;
    }
    .creatorProtection-disc {
      margin-bottom: 3rem;
    }
    .creatorProtection-sub_headings {
      margin-bottom: 2rem;
    }
    .creatorProtection-points {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .sub-heading {
      color: #f5f9fc;
      font-weight: 500;
      font-size: 2.6rem;
    }

    .dataProtection-description {
      color: #f5f9fc;
      font-weight: 300;
      font-size: 2.6rem;
    }

    .content-heading {
      color: #8b6cd8;
      font-weight: 800;
      font-size: 3.5rem;
    }
  }
}

@media (max-width: 600px) {
  .dataProtection {
    .dataProtection-inside {
      margin: 0 2rem 0 2rem;
      padding: 4.6rem 0 5.2rem 0;
    }
  }
}
