.blog {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.blog-image>img {
    width: 90%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2rem;
    margin-top: 1.5rem;
}

.blog-inside {
    padding: 2rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    border-radius: 1rem;
    background-color: #f8f9fa;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-family: "Sen",
        sans-serif;
    color: #313135;
}

.blog-heading>h2 {
    text-align: center !important;
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 500;
    color: #1A3677;
}

.blog-content > h3 {
    font-weight: bold;

    font-size: 2.5rem;
    margin-top: 2rem;

}

.blog-content > p {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
}

.remove-break {
    display: inline;
}

@media (max-width:600px) {

    .blog-inside {
        width: 75%;
    }
    .blog-heading>h2 {
        font-size: 2rem;
    }

    .blog-content > h3 {
        font-size: 1.5rem;
    }

    .blog-content > p {
        font-size: 1rem;
    }
}
