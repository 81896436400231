.gettingStarted {
    .gettingStarted-white {
        .gettingStarted-white-inside {
            margin: 15rem 20rem;

            .gettingStarted-white-description {
                margin-bottom: 7rem;
            }

            .gettingStarted-app-buttons {
                display: flex;
                justify-content:space-evenly;
                img {
                    width: 45rem;
                    height: 18rem;
                }
            }

            .gettingStarted-white-points {
                margin-top: 7rem;
            }
        }
    }

    .gettingStarted-blue {

        .gettingStarted-blue-inside {
            margin: 0 20rem;
            padding: 20rem 0;

            .gettingStarted-blue-points {
                margin-top: 7rem;

                .point {
                    margin-bottom:2.2rem;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .gettingStarted {
    .gettingStarted-white {
        .gettingStarted-white-inside {
            margin: 3rem 3rem;

            .gettingStarted-white-description {
                margin-bottom: 3rem;
            }

            .gettingStarted-app-buttons {
                display: flex;
                justify-content:space-evenly;
                img {
                    width: 25rem;
                    height: 10rem;
                }
            }

            .gettingStarted-white-points {
                margin-top: 2rem;
            }
        }
    }

    .gettingStarted-blue {

        .gettingStarted-blue-inside {
            margin: 0 3rem;
            padding: 3rem 0;

            .gettingStarted-blue-points {
                margin-top: 3rem;

                .point {
                    margin-bottom:0.3rem;
                }
            }
        }
    }
}
}