.security {
  .forinvestors-banner {
    width: 170.4rem;
    height: 63rem;
    border-radius:2rem;
    margin:0 auto;
    display:flex;
    margin-top:7rem;
}

  .security-inside {
    margin: 0 auto;
    height: auto;
    margin-top: 8rem;
    margin: 8.4rem 15.1rem 10rem 15.1rem;
   
    .security-content {
      margin-top: 2.6rem;
      height: 20rem;

      h1 {
        font-size: 6.4rem;
      }

      p {
        margin-top: 2.6rem;
        font-weight: 500;
      }

      
    }
  }

  .security-app-buttons {
                display: flex;
                justify-content:space-evenly;
                img {
                    width: 45rem;
                    height: 18rem;
                }
            }
  
}

@media (max-width: 600px) {
  .security {
    padding-bottom: 0;


  .forinvestors-banner {
    width: 90%;
    height: auto;
  }

    .security-inside {
      margin: 0 2rem;
      width: auto;
      margin-top: 5rem;

      .security-content {
        margin-top: 2.6rem;
        width: auto;
        height:auto;
        padding: 2.5rem;

        h1 {
          font-size: 6.4rem;
        }

        p {
          margin-top: 0;
          width: auto;
          font-weight: 500;
        }
      }
    }
    .security-app-buttons {
                display: flex;
                justify-content:space-evenly;
                img {
                    width: 25rem;
                    height: 10rem;
                }
            }
  }
}
