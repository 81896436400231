.landingPageCommon {
  .landingPageCommon-landing-container {
    display: flex;
    .landingPageCommon-inside {
      display: flex;
      justify-content: space-between;
      margin: auto 13rem;
      margin-top: 10rem;
      padding-bottom: 18rem;

      .landingPageCommon-content {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h1 {
          width: 76rem;
          text-align: start;
        }

        p {
          margin-top: 6.5rem;
          width: 72.3rem;
          text-align: start;
        }
      }

      .landingPageCommon-image {
        position: relative;

        .screen-animate-landingPageCommon {
          animation: screen-animate-landingPageCommon 1s;
          position: relative;
          left: 75%;
          width: 38.5rem;
          height: 78rem;
        }
        @keyframes screen-animate-landingPageCommon {
          0% {
            transform: translate(-0%, 30%);
          }

          100% {
            transform: translate(-0%, -0%);
          }
        }
      }
    }
  }
}

.screen-animate-landingPageCommon-screenRight {
  animation: screen-animate-landingPageCommon-screenRight 1s;
  position: absolute;
  left: 121%;
  width: 30.2rem;
  height: 62.2rem;
  top: 50%;
  transform: translate(0%, -50%);
}

@keyframes screen-animate-landingPageCommon-screenRight {
  0% {
    transform: translate(-50%, -10%);
  }

  100% {
    transform: translate(-0%, -50%);
  }
}

.screen-animate-landingPageCommon-screenLeft {
  animation: screen-animate-landingPageCommon-screenLeft 1s;
  position: absolute;
  width: 30.2rem;
  height: 62.2rem;
  top: 50%;
  left: 28%;
  transform: translate(25%, -50%);
}
@keyframes screen-animate-landingPageCommon-screenLeft {
  0% {
    transform: translate(50%, -10%);
  }

  100% {
    transform: translate(25%, -50%);
  }
}
@media (max-width: 900px) {
  .landingPageCommon {
    min-height: auto;
    .landingPageCommon-landing-container {
      height: auto;
      display: block;
      transform: translateY(0);
      .landingPageCommon-inside {
        display: block;
        margin: 2rem;
        padding: 2.9rem 0 3rem 0;

        .landingPageCommon-content {
          margin-top: 1rem;
          margin-left: 0;

          h1 {
            text-align: center;
            width: auto;
            margin-bottom: 3rem;
          }

          p {
            margin-top: 2rem;
            width: auto;
            margin-bottom: 2rem;
            text-align: center;
            height: 9.5rem;
          }

          button {
            display: block;
            margin: 3rem auto 5rem auto;
            width: 14rem;
            height: 3rem;
            font-size: 1.3rem;
          }
        }
        .landingPageCommon-image {
          position: relative;

          .screen-animate-landingPageCommon {
            animation: screen-animate-landingPageCommon 1s;
            position: relative;
            left: 40%;
            width: 30%;
            height: auto;
          }
          @keyframes screen-animate-landingPageCommon {
            0% {
              transform: translate(-0%, 30%);
            }

            100% {
              transform: translate(-0%, -0%);
            }
          }
        }
      }
    }
  }

  // .screen {
  //   width: 30%;
  //   height: auto;
  // }

  .screen-animate-landingPageCommon-screenRight {
    animation: screen-animate-landingPageCommon-screenRight 1s;
    left: 1%;
    width: 27%;
    height: auto;
    transform: translate(85%, -87%);
    top: 83%;
  }

  @keyframes screen-animate-landingPageCommon-screenRight {
    0% {
      transform: translate(35%, -30%);
    }

    100% {
      transform: translate(85%, -87%);
    }
  }

  .screen-animate-landingPageCommon-screenLeft {
    animation: screen-animate-landingPageCommon-screenLeft 1s;
    width: 27%;
    top: 45%;
    height: auto;
    transform: translate(135%, -87%);
    top: 83%;
    left: 24%;
  }
  @keyframes screen-animate-landingPageCommon-screenLeft {
    0% {
      transform: translate(185%, -30%);
    }

    100% {
      transform: translate(135%, -87%);
    }
  }

  .screen_animate_landingPageCommon {
    animation: screen_animate_landingPageCommon 1s;
    z-index: 100;
    position: relative;
  }
  @keyframes screen_animate_landingPageCommon {
    0% {
      transform: translate(-0%, 50%);
    }

    100% {
      transform: translate(-0%, 0%);
    }
  }
}

@media (max-width: 600px) {
  .landingPageCommon {
    min-height: auto;
    .landingPageCommon-landing-container {
      height: auto;
      display: block;
      transform: translateY(0);
      .landingPageCommon-inside {
        display: block;
        margin: 2rem;
        padding: 2.9rem 0 3rem 0;

        .landingPageCommon-content {
          margin-top: 1rem;
          margin-left: 0;

          h1 {
            text-align: center;
            width: auto;
            margin-bottom: 3rem;
          }

          p {
            margin-top: 2rem;
            width: auto;
            margin-bottom: 2rem;
            text-align: center;
            height: 9.5rem;
          }

          button {
            display: block;
            margin: 3rem auto 5rem auto;
            width: 14rem;
            height: 3rem;
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  .screen-animate-landingPageCommon-screenRight {
    animation: screen-animate-landingPageCommon-screenRight 1s;
    left: 39%;
    width: 10.6rem;
    height: 23.2rem;
    transform: translate(85%, -87%);
    top: 63%;
  }

  @keyframes screen-animate-landingPageCommon-screenRight {
    0% {
      transform: translate(35%, -30%);
    }

    100% {
      transform: translate(85%, -87%);
    }
  }

  .screen-animate-landingPageCommon-screenLeft {
    animation: screen-animate-landingPageCommon-screenLeft 1s;
    width: 10.6rem;
    height: 23.2rem;
    transform: translate(135%, -87%);
    top: 63%;
    left: -1%;
  }
  @keyframes screen-animate-landingPageCommon-screenLeft {
    0% {
      transform: translate(185%, -30%);
    }

    100% {
      transform: translate(135%, -87%);
    }
  }

  .screen_animate_landingPageCommon {
    animation: screen_animate_landingPageCommon 1s;
    z-index: 100;
    position: relative;
  }
  @keyframes screen_animate_landingPageCommon {
    0% {
      transform: translate(-0%, 50%);
    }

    100% {
      transform: translate(-0%, 0%);
    }
  }
}
