.contentCreator{
    display: flex;
    flex-direction: column;
    justify-content:center;

    .contentCreator-inside {
        width: 155rem;
        margin: 6rem auto 0rem auto;
       .contentCreator-heading{
           margin-bottom: 4rem;
       }

        .contentCreator-images {
            display: flex;
            justify-content: space-between;
            margin-bottom:5rem;
        }
    }
}

h3.contentCreator-one {
    color: #E79843;
}

h3.contentCreator-two {
    color: #C792EB;
}

h3.contentCreator-three {
    color: #1F89CA;
}

@media (max-width: 600px) {
    .contentCreator{
        justify-content: start;
    
        .contentCreator-inside {
            width: auto;
            margin: 5rem 3rem 5rem 3rem;
    
            .contentCreator {

                img {
                    height:30.8rem;
                    width: 14.32rem;
                }
    
               
    
                
            }
        }
    }
}
.screen-animate-contentCreator {
    animation: screen_animate_contentCreator 1s;
    }
    
    @keyframes screen_animate_contentCreator {
        0% {
            opacity: 0;
            transform: translateX(-150%);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }
    
    .text-anime{
        animation: text-anime 2s ease-out;
  
  }
        @keyframes text-anime {
            0% {
                opacity: 0;
            }
            50% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }