.investorFeatures {
  .investorFeatures-inside {
    margin: 8rem 15.1rem 10rem 15.1rem;

    .points {
      margin-top: 5rem;
    }
  }
}

@media (max-width: 600px) {
  .investorFeatures {
    .investorFeatures-inside {
      margin: 6rem 3rem;

      .points {
        margin-top: 3rem;
      }
    }
  }
}
