.terms {
    align-items: center;
    width: 70%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.terms>h2 {
    font-weight: 400;
    font-size: 3rem;
    padding-top: 4rem;
    padding-bottom: 2rem;

}

.terms>p {
    padding-bottom: 5rem;
    font-family: "Sen", sans-serif;
    font-size: 2rem;
}
.contentHeading1 {
    font-size: 2rem;
}
@media (max-width:600px) {
    .terms > h2 {
        font-size: 2rem;
    }

    .terms > p {
        font-size: 1.2rem;
        letter-spacing: 0.05rem;
    }
}