.technology {
    .technology-inside {
        width: 157.5rem;
        margin: 0 auto;
        padding: 6rem 0;

        .technology-heading {
            width: 150rem;
            text-align: center;
            margin: auto;
        }

        .technology-contents {
            .technology-content {
                margin-top: 10.4rem;
                display: flex;
                justify-content: space-between;

                .technology-leftContent {
                    margin-right: 8rem;
                }

                img {
                    width: 71rem;
                    height: 47.6rem;
                }

                div {
                    h1 {
                        margin-top: 6rem;
                        margin-bottom: 2rem;
                        font-size:3rem;
                    }
                    p {
                    }
                }

                
            }
        }
    }
}


@media (max-width: 600px) {
    .technology {
        .technology-inside {
            width: auto;
            margin: 3rem;
    
            .technology-heading {
                width: auto;
                text-align: center;
                margin: auto;
            }
    
            .technology-contents {
                .technology-content {
                    margin-top: 0;

                    .technology-leftContent {
                    margin-right: 0;
                }
    
    
                    img {
                        width: 35.5rem;
                        height: 23.8rem;
                    }
    
                    div {
                        h1 {
                            margin-top: 5rem;
                            margin-bottom: 1rem;
                            font-size:3rem;
                        }

                        p {
                            margin-top: 3rem;
                        }
                    }
    
                    
                }
            }
        }
    }
}

.screen-animate-AI {
    animation: screen_animate_AI 1s;
    }
    
    @keyframes screen_animate_AI {
        0% {
            opacity: 0;
            transform: translateX(-150%);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }



    .screen-animate-blockchain {
        animation: screen_animate_blockchain 1s;
        }
            
        @keyframes screen_animate_blockchain {
            0% {
                opacity: 0;
                transform: translateX(150%);
            }
            
            100% {
                opacity: 1;
                transform: translateX(0%);
            }
        }