.augumented {
    .augumented-inside {
        display: flex;
        justify-content: space-between;
        margin: 8.3rem 37rem 8rem 24rem;

        .augumented-content {
            width: 80rem;
            
            h5 {
                margin-top: 2.4rem;
                margin-bottom: 4.7rem;
                font-size: 2.7rem;
                font-weight: bold;
            }

            p {

                width:71.1rem;
                font-size: 2rem;
            }

            div {
                margin-top: 4.6rem;
                display: flex;
                justify-content:space-between;
                width: 27rem;
                img {
                    width: 8.3rem;
                    height: 8.5rem;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .augumented {
        .augumented-inside {
            margin: 2rem 3rem;
    
            .augumented-content {
                width: 80rem;

                h1 {
                    margin-bottom: 3.6rem;
                }
                
                h5 {
                    margin-top: 3.5rem;
                    margin-bottom: 3rem;
                }
    
                p {
                    width:auto;
                }
    
                div {
                    margin-top: 4.6rem;
                    width: 27rem;
                    justify-content: start;
                    margin-bottom: 3rem;

                    img {
                        width: 6.1rem;
                        height: 6.1rem;
                        margin-right: 3rem;
                    }
                }
            }
        }
    }
}