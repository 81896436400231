.point {
  display: flex;
        margin-bottom: 5rem;

  img {
    width: 4.6rem;
    height: 4.6rem;
    text-align: center;
    line-height: 6.24rem;
    transform: translateY(0.8rem);
    margin-right: 2.4rem;
  }

  p {
    font-weight: 300;
    margin-bottom: 0.7rem;
  }
}

.subHeading-description {
  margin-bottom:1rem;
  font-weight: 800;
  font-size: 3.5rem;
}

@media (max-width: 600px) {
  .point {
    img {
      width: 1.7rem;
      height: 1.7rem;
      line-height: 4rem;
      transform: translateY(0.6rem);
      margin-right: 1.1rem;
    }

    p {
        margin-bottom: 2rem;
      font-size: 1.6rem;
    }
  }

  .subHeading-description {
    h3 {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.6rem;
    }
   }
}
