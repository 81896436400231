.creatorSecurity {
  .creatorSecurity-inside {
    justify-content: space-between;
    margin: 0 23rem 0 23rem;
    padding: 0rem 0 12.6rem 0;
  }

  .prop-one {
    color: #1f89ca;
  }
  .prop-two {
    color: #d878ff;
  }
  .prop-three {
    color: #ffa621;
  }
}

@media (max-width: 600px) {
  .creatorSecurity {
    .creatorSecurity-inside {
      justify-content: space-between;
      margin: 0 2rem 0 2rem;
      padding: 3rem 0 4.2rem 0;
    }
  }
}
