.referral {
    width: 100%;
    min-height:100vh;
    display:flex;
    justify-content:center;
    flex-direction:column;
    
    .referral-inside {
        width: 156.6rem;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding: 10rem 0;

        .referral-content {
            margin-top: 13.4rem;
            width: 71rem;

            .referral-heading {
                font-size: 4.8rem;
                font-weight: 600;
            }

            .referral-description {
                margin-top: 4.5rem;
                margin-bottom: 3.4rem;
                font-family: 'Poppins';
                font-style: italic;
                font-weight: 400;
                font-size: 2.4rem;
                line-height: 4rem;
            }

            .referral-email {

                p {
                    font-weight: 400;
                    font-size: 2.4rem;

                    img {
                        margin-right: 2rem;
                        height:1.73rem;
                        width: 2.6rem;
                    }
                }
            }

            .referral-getApp {
                margin-top: 4rem;
                font-style: italic;
                font-weight: 600;
                font-size: 1.8rem;
                margin-bottom: 2.5rem;
            }

            .referral-app-links {
                width: 52rem;
                display: flex;
                justify-content: space-between;

                img {
                    height: 7.7rem;
                    width: 25.4rem;
                }
            }
        }

        .referral-form {
            background-color: #ffffff;
            height: 87.3rem;
            width: 64.9rem;
            border-radius: 3rem;

            .referral-form-inside {
                width: 54.4rem;
                margin: auto;
                h3 {
                    margin-top: 5.7rem;
                    color:#1D2C5F;
                    font-weight: 600;
                    font-size: 3rem;
                    text-align: center;
                }

                div {
                    p {
                        margin-top: 4rem;
                        color: #262626;
                        font-weight: 500;
                        font-size: 2.4rem;
                    }

                    input {
                        margin-top: 1.6rem;
                        background-color: rgba(31, 137, 202, 0.1);
                        border: 0;
                        width: 50rem;
                        height: 7.5rem;
                        font-weight: 500;
                        font-size: 2rem;
                        padding: 0 2rem;
                        border-radius: 11px;
                    }
                }

                button {
                    margin-top: 9.5rem;
                    width: 43.4rem;
                    height: 7rem;
                    background-color: #1D2C5F;
                    font-weight: 600;
                    font-size: 2.4rem;
                    border-radius: 1rem;
                }
            }
        }
    }
}

.referral-success {
    display: flex;
    justify-content: center;
    flex-direction: row;


    min-height:100vh;
    display:flex;
    justify-content:center;
    flex-direction:column;

    .referral-success-inside {
        background-color: white;
        width: 64.9rem;
        height: 89rem;
        margin: 9.4rem 0 10rem 0;
        
        text-align: center;
        border-radius: 3rem;

        .referral-tick {
            width: 46rem;
            height: 31.2rem;
            margin-bottom: 9.3rem;
            margin-top: 10rem;
        }

        h3 {
            font-size: 3rem;
            color: #1D2C5F;
        }

        p {
            font-style: italic;
            font-weight: 600;
            font-size: 2.4rem;
            color: #1D2C5F;
            margin-top: 8.2rem;
            margin-bottom: 2.7rem;
        }

        .referral-app-links {
            margin: auto;
            width: 52rem;
            display: flex;
            justify-content: space-between;

            img {
                height: 7.7rem;
                width: 25.4rem;
            }
        }
    }
}



@media (max-width: 600px) {

    .referral {
        width: 100%;
        min-height:93vh;
        display:block;
        
        .referral-inside {
            width: 38.5rem;
            display: block;
            margin: 0 auto;
            padding: 9.8rem 0 13rem 0;
    
            .referral-content {
                margin-top: 0;
                width: auto;
    
                .referral-heading {
                    text-align: start;
                    font-size: 2.5rem;
                }
    
                .referral-description {
                    margin-top: 2rem;
                    margin-bottom: 1.4rem;
                    width: auto;
                    font-size: 1.2rem;
                    line-height: 3rem;
                    text-align: start;
                }
    
                .referral-email {
    
                    p {
                        text-align: start;
                        font-weight: 400;
                        font-size: 2.4rem;
    
                        img {
                            margin-right: 1rem;
                            height:1.73rem;
                            width: 2.6rem;
                        }
                    }
                }
    
                .referral-getApp {
                    text-align: start;
                    margin-top: 2rem;
                    font-style: italic;
                    font-weight: 600;
                    font-size: 1.3rem;
                    margin-bottom: 2rem;
                }
    
                .referral-app-links {
                    width: 27rem;
                    display: flex;
                    justify-content: space-between;
    
                    img {
                        height: 4rem;
                        width: 13rem;
                    }
                }
            }
    
            .referral-form {
                background-color: #ffffff;
                height: 51.8rem;
                width: 38.5rem;
                border-radius: 3rem;
    
                .referral-form-inside {
                    width: 32.2rem;
                    margin: auto;
                    
                    h3 {
                        padding-top: 2.2rem;
                        font-size: 2.4rem;
                    }
    
                    div {
                        p {
                            margin-top: 2.3rem;
                            color: #262626;
                            font-weight: 500;
                            font-size: 1.5rem;
                        }
    
                        input {
                            margin-top: 1.6rem;
                            background-color: rgba(31, 137, 202, 0.1);
                            border: 0;
                            width: 28rem;
                            height: 4.4rem;
                            font-weight: 500;
                            font-size: 1.2rem;
                            padding: 0 2rem;
                            border-radius: 11px;
                        }
                    }
    
                    button {
                        margin-top: 5.6rem;
                        width: 25.7rem;
                        height: 4.1rem;
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }


    .referral-success {
        min-height:93vh;
        display: flex;
        flex-direction: column;
        justify-content:center;
    
        .referral-success-inside {
            width: 38.5rem;
            height: 51.8rem;
            // margin: 9.4rem 0 10rem 0;
            margin: auto;
            text-align: center;
            border-radius: 3rem;
    
            .referral-tick {
                width: 28.4rem;
                height: 20.3rem;
                margin-bottom: 1.5rem;
                margin-top: 7rem;
            }
    
            h3 {
                font-size: 2.4rem;
            }
    
            p {
                font-size: 1.3rem;
                margin-top: 3.5rem;
                margin-bottom: 2.2rem;
            }
    
            .referral-app-links {
                width: 27rem;
                display: flex;
                justify-content: space-between;

                img {
                    height: 4rem;
                    width: 13rem;
                }
            }
        }
    }
    
}