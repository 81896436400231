.news {
  .news-inside {
   

    .news-icons-field {
      width: 155.3rem;
      margin: 8rem auto;
      .news-icon-row {
        display: flex;
        justify-content: space-between;
        text-align: center;

        div {
          margin-bottom: 11.8rem;

          .news-icon {
            border-radius: 50%;
            margin-bottom: 2.6rem;
            margin: auto;

            img {
              width: 11.8rem;
              height: 11.8rem;
            }
          }

          h5 {
            font-size: 3.1rem;
            font-weight: 700;
            width: 38rem;
            margin-bottom: 1.3rem;
          }

          p {
            font-size: 2.3rem;
            width: 41.4rem;
          }
        }
      }
    }

    .news-news { 
      .news-articles-container {
      margin: 0 12.1rem;
      padding: 10.2rem 0 8.5rem 0;
      h1 {
        font-size: 4rem;
        text-align: center;
        margin-bottom: 6.8rem;
        font-weight: 600;
      }

      .news-articles {
        display: flex;
        justify-content: space-between;

        div {
          width: 45.3rem;
          height: 55rem;
          background-color: #fff;
          border-radius: 2.1rem;
          padding: 2.9rem 3.4rem;

          img {
            width: 44.3rem;
            height: 28.8rem;
            border-radius: 2.1rem;
          }

          h5 {
            font-size: 3.1rem;
            margin-top: 3.7rem;
            margin-bottom: 1.4rem;
          }

          p {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
  }
}

@media (max-width: 600px) {
  .news {
    .news-inside {

      .news-icons-field {
        width: 90%;
        .news-icon-row {
          div {
            margin-bottom: 3rem;

            .news-icon {
              margin-bottom: 1.3rem;

              img {
                width: 4.7rem;
                height: 4.7rem;
              }
            }

            h5 {
              font-size: 1.5rem;
              width: 13rem;
              margin-bottom: 0.6rem;
            }

            p {
              font-size: 1.05rem;
              width: 13rem;
            }
          }
        }
      }

      .news-news {
        .news-articles-container {
      margin: 0 3rem;
      padding: 5rem 0 5rem 0;
        h1 {
          font-size: 2rem;
          margin-bottom: 3.3rem;
        }

        .news-articles {
          justify-content: center;
          flex-direction: column;

          div {
            margin: auto;
            margin-bottom: 3rem;
            width: 38.3rem;
            height: auto;
            padding: 2.1rem 2.5rem;

            img {
                margin: auto;
              width: 38.3rem;
              height: 23.6rem;
            }

            h5 {
              font-size: 2.2rem;
              margin-top: 1.8rem;
              margin-bottom: 0.7rem;
            }

            p {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
    }
  }
}
